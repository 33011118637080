import React from 'react'

export const ThunderSVG = () => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4151 29.0625C11.2581 29.0626 11.1029 29.0283 10.9604 28.9621C10.818 28.8958 10.6918 28.7992 10.5908 28.6789C10.4897 28.5587 10.4162 28.4178 10.3754 28.2661C10.3346 28.1144 10.3275 27.9557 10.3546 27.8009V27.7945L12.0017 18.75H5.6249C5.4479 18.7499 5.27452 18.6998 5.12482 18.6054C4.97512 18.511 4.8552 18.3761 4.77895 18.2163C4.7027 18.0566 4.67323 17.8785 4.69394 17.7028C4.71465 17.527 4.7847 17.3606 4.89599 17.223L17.738 1.34762C17.8842 1.16207 18.0871 1.0295 18.3157 0.970183C18.5444 0.91087 18.7862 0.928077 19.0041 1.01917C19.222 1.11027 19.4041 1.27024 19.5226 1.47463C19.641 1.67901 19.6892 1.91656 19.6599 2.15094C19.6599 2.16852 19.6552 2.18551 19.6522 2.20309L17.9993 11.25H24.3749C24.5519 11.25 24.7253 11.3001 24.875 11.3945C25.0247 11.489 25.1446 11.6239 25.2208 11.7836C25.2971 11.9433 25.3266 12.1214 25.3059 12.2972C25.2851 12.473 25.2151 12.6393 25.1038 12.7769L12.2601 28.6523C12.1591 28.7798 12.0308 28.8829 11.8845 28.9539C11.7382 29.025 11.5777 29.062 11.4151 29.0625Z"
          fill="#F2C94C"
        />
      </svg>
    </>
  )
}
