import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import React from 'react'
import Footer from '../../components/Footer'
import { QuestionSVG } from '../../images/svgs/QuestionSVG'
import { ThunderSVG } from '../../images/svgs/ThunderSVG'

import './help-center.scss'

const HelpCenter = () => {
  return (
    <div className="help-center-container">
      <div className="col mx-auto mt-5">
        <div className="col mt-2 text-center">
          <h1>Help Center</h1>
          <p>Search our knowledge base or browse categories below</p>
        </div>
        <div className="col mt-5">
          <div>
            <input
              type={`text`}
              placeholder={`Search`}
              className={`w-100 input-info`}
            />
          </div>
          <div className="col">
            <Link to="#0">
              <div className="w-100 info-card mt-5 row sp-ev">
                <div className="row jt-cn">
                  <ThunderSVG />
                </div>
                <div className="col ml-5 ">
                  <h5>Features & Tips</h5>
                  <p>
                    Learn more about our features and what’s possible with AKTA
                    Analytics
                  </p>
                </div>
                <div className="row ml-5 w-20">
                  <FontAwesomeIcon
                    icon={faArrowRightLong}
                    className={`w-100`}
                  />
                </div>
              </div>
            </Link>
            <Link to="/faqs">
              <div className="w-100 info-card mt-5 row sp-ev">
                <div className="row jt-cn">
                  <QuestionSVG />
                </div>
                <div className="col ">
                  <h5>FAQ</h5>
                  <p>
                    Collection of the most asked questions using AKTA Analytics
                  </p>
                </div>
                <div className="row ml-5 w-20">
                  <FontAwesomeIcon
                    icon={faArrowRightLong}
                    className={`w-100`}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  )
}

export default HelpCenter
