import React from 'react'

export const QuestionSVG = () => {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 3.75C8.78906 3.75 3.75 8.78906 3.75 15C3.75 21.2109 8.78906 26.25 15 26.25C21.2109 26.25 26.25 21.2109 26.25 15C26.25 8.78906 21.2109 3.75 15 3.75ZM14.6484 21.5625C14.4167 21.5625 14.1901 21.4938 13.9974 21.365C13.8047 21.2362 13.6545 21.0532 13.5658 20.8391C13.4771 20.6249 13.4539 20.3893 13.4991 20.162C13.5443 19.9347 13.6559 19.7259 13.8198 19.562C13.9837 19.3981 14.1925 19.2865 14.4198 19.2413C14.6471 19.1961 14.8828 19.2193 15.0969 19.308C15.311 19.3966 15.494 19.5469 15.6228 19.7396C15.7516 19.9323 15.8203 20.1588 15.8203 20.3906C15.8203 20.7014 15.6968 20.9995 15.4771 21.2193C15.2573 21.439 14.9592 21.5625 14.6484 21.5625ZM16.6078 15.5859C15.658 16.2234 15.5273 16.8076 15.5273 17.3438C15.5273 17.5613 15.4409 17.77 15.2871 17.9238C15.1332 18.0776 14.9246 18.1641 14.707 18.1641C14.4895 18.1641 14.2808 18.0776 14.127 17.9238C13.9731 17.77 13.8867 17.5613 13.8867 17.3438C13.8867 16.06 14.4773 15.0393 15.6926 14.223C16.8223 13.4648 17.4609 12.9844 17.4609 11.9279C17.4609 11.2096 17.0508 10.6641 16.2018 10.2604C16.002 10.1654 15.5572 10.0729 15.01 10.0793C14.3232 10.0881 13.79 10.2521 13.3793 10.5826C12.6047 11.2061 12.5391 11.8846 12.5391 11.8945C12.5339 12.0023 12.5075 12.1079 12.4615 12.2054C12.4155 12.303 12.3507 12.3905 12.2708 12.463C12.191 12.5355 12.0976 12.5916 11.9961 12.628C11.8946 12.6644 11.7869 12.6805 11.6792 12.6753C11.5715 12.6701 11.4658 12.6437 11.3683 12.5977C11.2708 12.5517 11.1832 12.4869 11.1107 12.4071C11.0382 12.3272 10.9822 12.2339 10.9457 12.1324C10.9093 12.0308 10.8932 11.9232 10.8984 11.8154C10.9049 11.673 11.0039 10.3904 12.3498 9.30762C13.0477 8.74629 13.9354 8.45449 14.9865 8.4416C15.7307 8.43281 16.4297 8.55879 16.9037 8.78262C18.3223 9.45352 19.1016 10.5721 19.1016 11.9279C19.1016 13.9102 17.7768 14.8002 16.6078 15.5859Z"
          fill="#D75281"
        />
      </svg>
    </div>
  )
}
